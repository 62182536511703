
    @import "config.scss";
 
.form {
  position: relative;
  width: 100%;
  overflow: visible;
  border: none;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .title {
    margin-bottom: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .inputContainer {
    position: relative; /* Necesario para el posicionamiento de la etiqueta flotante */
    width: 100%;
  }

  .input {
    padding-left: 20px;
    font-size: 1rem;
    height: 3rem;
    border-radius: var(--br-soft);
    border: 1px solid var(--color-white-dark);
    letter-spacing: 0.8px;
    background-color: transparent;
    color: var(--color-white-dark);

    &:focus {
      border: 1px solid var(--color-primary);
    }
    &:placeholder-shown {
      text-overflow: ellipsis;
    }

    &:focus-visible {
      outline: none;
    }

    &.startIcon {
      padding-left: 50px;
    }

    &.brHard {
      border-radius: 25px;
    }

    &.filled {
      background-color: white;
      border-color: black;
    }
  }

  .input:disabled {
    background-color: var(--color-grey-light);
  }

  /* Estilos para la etiqueta flotante */
  .label {
    position: absolute;
    left: 20px;
    font-size: 0.75rem;
    color: var(--color-white-light);
    pointer-events: none;
    background-color: var(--color-background);
    padding-inline: 0.5rem;
    transform: translate(0, -50%);
  }

  .label.floating {
    top: 0;
    left: 20px;
    font-size: 0.75rem;
    color: var(--color-white-light) !important;
    background-color: var(--color-background);
    padding-inline: 0.5rem;
  }
}

.errorMessage {
  @include errorMessage;
}

.inputError {
  margin-bottom: 10px;
}

.checkbox {
  position: relative;
  width: 100%;
  display: flex;
  gap: 1rem;
  flex: 1;
  align-items: center;
  margin-bottom: 11px;

  .title {
    margin-bottom: 10px;
  }

  input[type='checkbox'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    width: 1.5rem;
    height: 1.5rem;
    border: 0.15em solid grey;
    border-radius: 0.15em;
    display: grid;
    place-content: center;
    flex: none;
    cursor: pointer;
  }

  input[type='checkbox'] + label {
    left: 2rem;
  }

  input[type='checkbox']:checked::after {
    content: url('../../../public/valid-icon.svg');
    position: relative;
    top: 0%;
    left: -60%;
    width: 1rem;
    height: 1rem;
    transition: 120ms transform ease-in-out;
    background: var(--color-primary);
  }
}

.passwordIcon {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  bottom: 0.8rem;
  right: 28px;
}
